/**
 *
 * INJECTED AJAX FORMS: Displayed as inline forms
 * Twig:
 * 1. form twig must extend generic/form/ajax_form.html.twig
 * 2. Do not add cancel/submit buttons in Form type class
 *
 * trigger element: element that will open form for add/edit, Check AjaxInjectedFormConfig
 * submit_button: submit button that will perform ajax form submit Check AjaxSubmitFormConfig
 * 1. edit mode should be triggered in the same box where related item is displayed
 * 2. hide trigger element when form is opened (default behavior)
 * 3. container element: where form and result is displayed after submit or on form open
 * 4. update element: where submit result will displayed, if not defined container_element will be used
 * 5. append: if param exists, 1=append to the beggining of update element, 2=append to the bottom of update element, if not exists replace update element content with response
 * 6. MULTIPLE inject forms cna be active at the same time
 * 7. if pagination is used, use combination of update_element (where to store result) and objectId (where to scroll to the new added item)
 * 8. All ajax responses should contain success param (use AjaxResponse model)
 *
 */

const InjectedForm = [];

/**
 * Submit button available data properties:
 * data-confirmationtitle | confirmation box title, if exists confirmation will be displayed
 * data-confirmationmessage | confirmation box additional message
 * data-confirmationtype | [confirm,alert] | default confirm
 * data-confirmationyes | yes button text
 * data-confirmationno | no button text
 *
 */
function AjaxSubmitFormConfig(confirmTitle, confirmMessage, confirmButtonText, cancelButtonText){
  this.confirmTitle = confirmTitle;
  this.confirmMessage = confirmMessage;
  this.yesText = confirmButtonText;
  this.noText = cancelButtonText;

  this.hasConfirmation = () => !!this.confirmMessage;

  this.getTitle = () => this.confirmTitle ? this.confirmTitle : 'Are you sure?';

  this.getYesText = () => this.yesText ? this.yesText : 'Ok';

  this.getNoText = () => this.noText ? this.noText : 'Cancel';
}

/**
 * Ajax form trigger button available data properties
 * data-callbacks | list of js callbacks to execute after ajax defined as comma separated
 * data-callbacksbefore | list of js callbacks to execute before response load defined as comma separated
 * data-container | REQUIRED|  container id where form will be loaded
 * data-hide_elements | list of elements (by id) to hide before form load, defineds as CSV list
 * data-update_element | element id where to append resultView, if not defined container will be used
 * data-url | REQUIRED| action url
 * data-objectId | element id for scrollto action after ajax call
 * data-autoload | trigger ajax form loading when trigger element is loaded
 * data-append | if param exists, 1=append to the top of update element, 2=append to the bottom of update element, 3=replace container/update element with reuslt view if not exists replace update element content with response
 * data-disable_button | if present trigger element will be disabled instead hidden
 */
function AjaxInjectedFormConfig(formId, previousHtml, callbacks, container, hide_elements, update_element, url, scrollto, append, autoload, callbacksbefore, disable_button, trigger_button_available, target_blank) {
  this.formId = formId; // unique form id
  this.previousHtml = previousHtml; // previous container html
  this.callbacks = callbacks; // js callback, fired on success
  this.container = container; // container id, response placeholder
  this.hide_elements = hide_elements; // elements ids to hide on ajax call
  this.update_element = update_element; // element id where to load response in case that it is different that container
  this.url = url; // ajax call url
  this.scrollto = scrollto; // scroll to element id on success
  this.flashMessage = null; // flash message to display (type, message)
  this.append = append; // append state: null: replace update element content, 1- top, 2-bottom
  this.autoload = autoload;
  this.callbacksbefore = callbacksbefore;
  this.colspan = 1;
  this.disable_button = disable_button;
  this.trigger_button_available = trigger_button_available;
  this.target_blank = target_blank;

  this.getFormSelector = () => `#${ this.formId }`;

  this.getFormObject = () => $(`#${ this.formId }`);

  this.getCallBackMethods = () => this.callbacks ? this.callbacks.split(',') : [];

  this.getCallBackBeforeMethods = () => this.callbacksbefore ? this.callbacksbefore.split(',') : [];

  this.getHideElementsList = () => this.hide_elements ? this.hide_elements.split(',') : [];

  this.getContainerObject = () => $(`#${ this.container }`);

  this.getUpdateObject = () => this.update_element ? $(`#${ this.update_element }`) : this.getContainerObject();

  this.isTr = () => this.getUpdateObject().is('tr');

  this.isAutoLoad = () => !!(this.autoload && this.autoload == 1);

  this.isAppendStateTop = () => !!(this.append && this.append == 1);

  this.isAppendStateBottom = () => !!(this.append && this.append == 2);

  this.isAppendStateReplace = () => !!(this.append && this.append == 3);

  this.getScrollToObject = () => $(`#${ this.scrollto }`);

  this.isDisabledButton = () => !!this.disable_button;

  this.triggerButtonAvailable = () => this.trigger_button_available ? this.trigger_button_available : 0;

  if (this.isTr()){
    this.colspan = this.getUpdateObject().children('td').length;
  }

  this.targetBlank = () => !!this.target_blank;
}

function AjaxInjectedForm(trigger_element, formId){
  this.formConfig = new AjaxInjectedFormConfig(formId, '',
    getDataValue(trigger_element, 'callbacks'),
    getDataValue(trigger_element, 'container'),
    getDataValue(trigger_element, 'hide_elements'),
    getDataValue(trigger_element, 'update_element'),
    getDataValue(trigger_element, 'url'),
    getDataValue(trigger_element, 'scrollto'),
    getDataValue(trigger_element, 'append'),
    getDataValue(trigger_element, 'autoload'),
    getDataValue(trigger_element, 'callbacksbefore'),
    getDataValue(trigger_element, 'disable_button'),
    getDataValue(trigger_element, 'trigger_button_available'),
    getDataValue(trigger_element, 'target_blank'));
  this.fillInvisibleContentFlag = getDataValue(trigger_element, 'fill_invisible_content');
  this.elementId = formId;
  this.triggerElement = trigger_element;
  this.flashMessage = null;
  this.modalResponseView = null;
  this.modalNotClosableResponseView = null;
  this.state = 'active';
  this.hideButtonWhenAutoLoad = undefined === trigger_element.data('hide_button_when_auto_load') ? 0 : trigger_element.data('hide_button_when_auto_load')
  this.cancel = function (){
    const _this = this;
    if (!_this.formConfig.autoload){
      _this.formConfig.getContainerObject().html(_this.formConfig.previousHtml);
      _this.formConfig.getContainerObject().find(".has-tip").foundation();
      _this.showHiddenElements();
      _this.scrolltobox();
      _this.enableButton();
      // self.triggerElement.show();
    }
  };
  this.scrolltobox = function (scrollToId){
    const _this = this;
    const element = _this.formConfig.getScrollToObject();
    if (element && element.length){
      scrollToElement(element);
    }
  };
  this.autoLoad = function (){
    const _this = this;
    $.ajax({
      type: 'GET',
      url: _this.formConfig.url,
      dataType: 'JSON',
      contentType: false,
      processData: false,
      beforeSend: function (){
        if (_this.hideButtonWhenAutoLoad) {
          _this.formConfig.previousHtml = _this.formConfig.getContainerObject().html();
          _this.disableButton();
        }
      },
      statusCode: {
        406: function (result){
          FlashMessage.showErrorMessage(result.responseText);
          InjectedForm[_this.formConfig.formId].cancel();
        }
      },
      success: function (data){
        if (data.success){
          _this.formConfig.getContainerObject().html(data.responseView);
          const containerElement = _this.formConfig.getContainerObject();
          const form = containerElement.find('form');
          _this.showGrecaptcha(form);
          $(form).attr('id', _this.elementId);
          reinitTooltip();
        } else {
          _this.enableButton();
          // self.triggerElement.show();
        }

        if (data.hasOwnProperty('flashMessage')){
          _this.flashMessage = data.flashMessage;
        }

        const callBackMethods = _this.formConfig.getCallBackMethods();
        for (var i = 0; i < callBackMethods.length; i++){
          var func = window[callBackMethods[i]];
          var retCallback = func(data);
        }

      },
      complete: function (){
        _this.showFlashMessage();
      },
      error: function (){
        _this.enableButton();
        // self.triggerElement.show();
      }
    });
  };
  this.disableButton = function (){
    if (!this.formConfig.triggerButtonAvailable()){
      if (this.formConfig.isDisabledButton()){
        this.triggerElement.prop('disabled', true);
      } else {
        this.triggerElement.hide();
      }
    }
  };
  this.enableButton = function (){
    if (!this.formConfig.triggerButtonAvailable()) {
      if (this.formConfig.isDisabledButton()){
        this.triggerElement.prop('disabled', false);
      } else{
        this.triggerElement.show();
      }
    }
  };
  const _this = this;
  this.showGrecaptcha = function (wrapper){
      let target = wrapper.find('.recaptcha');
      if (target.length) {
        grecaptcha.ready(function() {
          grecaptcha.render(target.get(0), {
            'sitekey': target.data('key'),
            'size': 'invisible',
            'callback': function (response) {
              target.find('[name=g-recaptcha-response]').val(response);
              _this.submittForm(_this.formConfig.getFormObject().find('.js-ajax-form-submit'));
            }
          });
        });
      }
  };
  this.start = function (){
    $.ajax({
      type: 'GET',
      url: _this.formConfig.url,
      dataType: 'JSON',
      contentType: false,
      processData: false,
      beforeSend: function (){
        _this.formConfig.previousHtml = _this.formConfig.getContainerObject().html();
        _this.disableButton();
        // self.triggerElement.hide();
      },
      statusCode: {
        406: function (result){
          FlashMessage.showFlashMessages(result.responseText, 'danger');
          InjectedForm[_this.formConfig.formId].cancel();
        },
        403: function (result){
          FlashMessage.showErrorMessage(result.statusText);
          InjectedForm[_this.formConfig.formId].cancel();
        }
      },
      success: function (data){
        if (data.success){
          let responseView = data.responseView;
          if (_this.formConfig.isTr()){
            responseView = "<td colspan='" + _this.formConfig.colspan + "'>" + responseView + '</td>';
          }
          _this.formConfig.getContainerObject().html(responseView);
          const form = _this.formConfig.getContainerObject().find('form');
          _this.showGrecaptcha(form);
          $(form).attr('id', _this.elementId);
          reinitTooltip();
          _this.hideHiddenElements();
        } else {
          _this.enableButton();
          // self.triggerElement.show();
        }

        if (data.hasOwnProperty('flashMessage')){
          _this.flashMessage = data.flashMessage;
        }
      },
      complete: function (){
        _this.showFlashMessage();
        if(_this.formConfig.scrollto){
          const elementForScrolling = document.getElementById(_this.formConfig.scrollto);
          if(elementForScrolling){
            scrollToElement($(elementForScrolling));
          }
        }
      },
      error: function (){
        _this.enableButton();
        // self.triggerElement.show();
      }
    });
  };
  this.showFlashMessage = function (){
    if (this.flashMessage){
      FlashMessage.showShortMessage(this.flashMessage);
    }
  };
  this.showModalResponseView = function (){
    if (this.modalResponseView){
      openFoundationMessage(this.modalResponseView, 'modal--basic');
    }
    if (this.modalNotClosableResponseView){
      openNotClosableFoundationMessage(this.modalNotClosableResponseView, 'modal--basic');
    }
  };
  this.submittForm = function (submitButton){
    const _this = this;
    const Data = new FormData();

    Data.append(submitButton.attr('name'), '');
    $.each($('input[type="file"]'), function (i, file_elem){
      Data.append($(file_elem).attr('name'), file_elem.files[0]);
    });

    $.each(_this.formConfig.getFormObject().serializeArray(), function (i, elem){
      Data.append(elem.name, elem.value);
    });

    _this.ajaxCall(Data);
  };
  this.ajaxCall = function (Data){
    const _this = this;
    $.ajax({
      type: 'POST',
      url: _this.formConfig.url,
      data: Data,
      dataType: 'JSON',
      contentType: false,
      processData: false,
      beforeSend: function (){
        blockElement(_this.formConfig.getFormObject());
      },
      statusCode: {
        406: function (result){
          FlashMessage.showErrorMessage(result.responseText);
          InjectedForm[_this.formConfig.formId].cancel();
        }
      },
      success: function (data){
        if (data.reload){
          location.reload();
        } else if (data.goToUrl){
          if (_this.formConfig.targetBlank()) {
            window.open(data.goToUrl);
          } else {
            window.location.href = data.goToUrl;
          }
        } else {
          let responseViewHtml = data.responseView;
          // reinitialize tooltip
          const callBackMethodsBefore = _this.formConfig.getCallBackBeforeMethods();
          for (var i = 0; i < callBackMethodsBefore.length; i++){
            var func = window[callBackMethodsBefore[i]];
            var retCallback = func(data);
          }
          if (data.success){
            if (data.state == 'active'){
              // multi forms case display additional form step
              if (_this.formConfig.isTr()){
                responseViewHtml = "<td colspan='" + _this.formConfig.colspan + "'>" + responseViewHtml + '</td>';
              }
              _this.formConfig.getContainerObject().html(responseViewHtml);
              const form = _this.formConfig.getContainerObject().find('form');
              $(form).attr('id', _this.elementId);
              _this.showGrecaptcha(_this.formConfig.getContainerObject());
            } else if (data.state == 'end'){
              _this.formConfig.getContainerObject().empty();
              if (_this.formConfig.isAppendStateTop()){
                _this.formConfig.getUpdateObject().prepend(responseViewHtml);
              } else if (_this.formConfig.isAppendStateBottom()){
                _this.formConfig.getUpdateObject().append(responseViewHtml);
              } else if (_this.formConfig.isAppendStateReplace()){
                _this.formConfig.getUpdateObject().replaceWith(responseViewHtml);
              } else {
                _this.formConfig.getUpdateObject().html(responseViewHtml);
              }
              if (_this.fillInvisibleContentFlag){
                fillInvisibleContent(_this.formConfig.getUpdateObject());
              }
              _this.enableButton();
              // self.triggerElement.show();
            }

            _this.showHiddenElements();
            reinitTooltip();
          }
        }
        const callBackMethods = _this.formConfig.getCallBackMethods();
        for (var i = 0; i < callBackMethods.length; i++){
          var func = window[callBackMethods[i]];
          var retCallback = func(data);
        }

        if (data.objectId){
          _this.formConfig.scrollto = data.objectId;
        }

        if (data.hasOwnProperty('flashMessage')){
          _this.flashMessage = data.flashMessage;
        }
        if (data.hasOwnProperty('modalResponseView')){
          _this.modalResponseView = data.modalResponseView;
        }
        if (data.hasOwnProperty('modalNotClosableResponseView')){
          _this.modalNotClosableResponseView = data.modalNotClosableResponseView;
        }
      },
      complete: function (response){
        unblockElement(_this.formConfig.getFormObject());
        _this.showFlashMessage();
        _this.scrolltobox();
        _this.showModalResponseView();
        if($('.feed-item-wrap').length) {
          $('.feed-items__wrap').find('.feedbox__empty').hide();
        }
      },
      error: function (response){
        if (response){
          if (response.responseJSON && response.responseJSON.hasOwnProperty('flashMessage')){
            _this.flashMessage = response.responseJSON.flashMessage;
          }
        }
        _this.cancel();
      }

    });
  };
  this.submit = function (submitButton){
    const _this = this;
    const submitConfig = new AjaxSubmitFormConfig($(submitButton).data('confirmationtitle'), $(submitButton).data('confirmationmessage'));

    if ($(submitButton).data('action') !== undefined){
      _this.formConfig.url = $(submitButton).data('action');
    }

    if (submitConfig.hasConfirmation()){
      const modal = `
            <div class="v2 reveal modal modal--confirm" id="foundation_confirmation">
                <button class=" close-button modal__close-btn js-close-confirmation-button" 
                        aria-label="Close reveal" type="button" data-close>
                    <span class="reveal--close" aria-hidden="true">×</span>
                </button>
                <button class="modal__icon modal__icon--confirm">
                    <svg class="icon icon-svg--question-circle"><use xlink:href="#svg--question-circle"></use></svg>
                </button>
                <div class="modal__heading"> ${ submitConfig.getTitle() } </div>
                <div class="modal__content modal__content--confirm"> ${ submitConfig.confirmMessage } </div>
                <div class="modal__actions modal__actions--confirm">
                    <div class="btn-group btn-group--rtl">
                        <button class="v2 btn btn--outline btn--md margin-right js-close-confirmation-button" data-close>
                            Cancel
                        </button>
                        <button class="v2 btn btn--primary btn--md " id="yes_confirmation">
                            ${ submitConfig.getYesText() }
                        </button>
                    </div>
                </div>
            </div>`;

      $('body').append(modal);
      const confirmationDialog = new Foundation.Reveal($('#foundation_confirmation'));
      confirmationDialog.open();
      const modal_object = $('#foundation_confirmation');

      $('#yes_confirmation').on('click', function (e){
        // close and REMOVE FROM DOM to avoid multiple binding
        confirmationDialog.close();
        modal_object.remove();
        // calling the function to process
        if (_this.formConfig.getFormObject().find('.recaptcha').length) {
          grecaptcha.execute();
        } else{
          _this.submittForm($(submitButton));
        }
      });
      $('.js-close-confirmation-button').on('click', function (e){
        // close and REMOVE FROM DOM to avoid multiple binding
        confirmationDialog.destroy();
        modal_object.remove();
        // calling the function to process
      });
      //
      //
      // confirmation_dialog(self.submittForm($(submitButton)),
      //     submitConfig.getTitle(),
      //     submitConfig.confirmMessage,
      //     submitConfig.getYesText());
    } else {
      if (_this.formConfig.getFormObject().find('.recaptcha').length) {
        grecaptcha.execute();
      } else {
        _this.submittForm($(submitButton));
      }
    }
  };
  this.showHiddenElements = function (){
    const elementsList = this.formConfig.getHideElementsList();
    for (let i = 0; i < elementsList.length; i++){
      $('#' + elementsList[i]).show();
    }
  };
  this.hideHiddenElements = function (){
    const elementsList = this.formConfig.getHideElementsList();
    for (let i = 0; i < elementsList.length; i++){
      $('#' + elementsList[i]).hide();
    }
  };
}

$(document).ready(function (){
  /**
     * open edit/create injected form
     */

  $(document).on('click', '.js-injected-form', function (){
    const formId = newGuid(); // $(this).data('form');
    InjectedForm[formId] = new AjaxInjectedForm($(this), formId);
    InjectedForm[formId].start();
  });
  /**
     * submit form
     */
  $(document).on('click', '.js-ajax-form-submit', function (e){ // submit form
    const formId = $(this).closest('form').attr('id');
    e.preventDefault();
    if (findDuplicatedFormIds(formId)){
      console.warn('Duplicated form id' + formId + ' detected!!');
    } else {
      if (InjectedForm[formId]){
        InjectedForm[formId].submit(e.target);
      } else { // to be used for debugging
        // console.log('Form with id ' + formId + ' does not exists!');
      }
    }
  });
  /**
     * cancel form
     */
  $(document).on('click', '.js-injected-form-cancel', function (){ // cancel form
    const closeElementId = $(this).closest('form').attr('id');
    const closeElementName = $(this).closest('form').attr('name');

    const formElementId = getParameter(closeElementId, closeElementName);
    if (InjectedForm[formElementId]){
      InjectedForm[formElementId].cancel();
      delete (InjectedForm[formElementId]);
    } else { // to be used for debugging
      // console.log('Form with id/name ' + formElementId + ' does not exists!');
    }
  });

  openInjectedForms();
});

function openInjectedForms(){
  $('.js-injected-form-box').each(function (i, obj){
    initInjectedForm($(obj));
  });
}

function initInjectedForm(element){
  const formId = newGuid(); // $(this).data('form');
  InjectedForm[formId] = new AjaxInjectedForm(element, formId);
  InjectedForm[formId].autoLoad();
}
